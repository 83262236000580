<template>
  <div>
    <div
      id="menu"
      :style="{ right: showGeneralSidebar ? '0px' : '-375px' }"
      class="menu_block"
    >
      <span class="close__menu" @click="toggleGeneralSidebar(false)">
        <img alt="" src="/img/icon_close.svg" />
      </span>
      <router-link
        v-if="isAuthenticated"
        :to="{ name: 'cabinet' }"
        class="item_link__menu"
      >
        {{ $t("cabinet.title") }}
      </router-link>
      <router-link :to="{ name: 'home' }" class="item_link__menu">
        {{ $t("food_delivery") }}
      </router-link>
      <!--      <router-link :to="{ name: 'news' }" class="item_link__menu">-->
      <!--        {{ $t("news.title") }}-->
      <!--      </router-link>-->
      <router-link :to="{ name: 'contact_us' }" class="item_link__menu">
        {{ $t("contact_us.title") }}
      </router-link>
      <a class="item_link__menu" href="tel:+37793104248">
        +377 93 10 42 48
      </a>
      <router-link
        v-if="!isAuthenticated"
        :to="{ name: 'login' }"
        class="item_link__menu"
      >
        {{ $t("sign_in") }} / {{ $t("sign_up") }}
      </router-link>
      <a v-else class="item_link__menu" @click="logout">{{ $t("logout") }}</a>

      <div class="lang_block">
        <a @click="setLocale('en')">
          <img alt="" src="/img/EN.png" />
        </a>
        <a @click="setLocale('fr')">
          <img alt="" src="/img/fr.png" />
        </a>
      </div>
    </div>

    <header :class="['header', { header_page: $route.name !== 'home' }]">
      <div class="container">
        <div class="content__header">
          <router-link :to="{ name: 'home' }" class="logo">
            <img v-if="$route.name === 'home'" alt="" src="/img/logo.svg" />
            <img v-else alt="" src="/img/logo_b.svg" />
          </router-link>
          <div class="panel__header">
            <router-link
              :to="{ name: 'favourites' }"
              class="favorites__header"
            ></router-link>
            <span
              v-click-outside="() => toggleCart(false)"
              class="cart__header"
              @click="toggleCart(!showCart)"
            >
              <span v-if="itemsCount > 0" class="count__cart_header">
                {{ itemsCount }}
              </span>
            </span>
            <div
              ref="cart_popup"
              :style="{ display: showCart ? 'block' : 'none' }"
              class="cart__header_block"
            >
              <div
                v-for="item in cart.items"
                :key="item.id"
                class="order__item"
              >
                <img
                  :src="'/storage/' + item.dish.image_menu"
                  alt="logo-pizza"
                  class="order__logo"
                />
                <div class="order__desc acc">
                  <div class="order__title">
                    <span>{{ item.dish.restaurant }}</span>
                  </div>
                  <span class="order__name">
                    {{ item.dish.name }} {{ item.dish.price_sell }}€
                  </span>
                  <span
                    v-for="addition in item.additions"
                    :key="addition.id"
                    class="order__size"
                  >
                    {{ addition.name }}
                    <span v-if="addition.price_sell > 0">
                      + {{ addition.price_sell }}€
                    </span>
                  </span>
                  <span class="order__text">{{ item.comment }}</span>
                  <div id="counter-2" class="counter">
                    <button
                      class="arr-down"
                      @click="
                        changeItemQuantity({ id: item.id, increment: false })
                      "
                    >
                      &lt;
                    </button>
                    <input
                      :value="item.quantity"
                      class="counter__num"
                      type="text"
                    />
                    <button
                      class="arr-up"
                      @click="changeItemQuantity({ id: item.id })"
                    >
                      &gt;
                    </button>
                  </div>
                  <div class="error_text" v-if="item.quantity == item.dish.stock" >
                    {{ $t("cart.max_amount") }} : {{ item.dish.stock }}
                  </div>
                  <button
                    class="order__btn-trash"
                    @click="removeItem(item.id)"
                  ></button>
                </div>
              </div>
              <div class="bottom_cart__header">
                <p>
                  {{ $t("cart.subtotal") }}:
                  <span class="summ">{{ cart.subtotal }}€</span>
                </p>
                <div class="text-center">
                  <button
                    v-if="itemsCount > 0"
                    class="goto_cart"
                    @click="gotoCart"
                  >
                    {{ $t("cart.go_to_cart") }}
                  </button>
                </div>
              </div>
            </div>
            <a
              v-click-outside="() => toggleGeneralSidebar(false)"
              class="menu__header"
              @click="toggleGeneralSidebar(!showGeneralSidebar)"
            ></a>
          </div>
        </div>
      </div>
    </header>
    <section v-if="$route.name === 'home'" id="home_slide">
      <Swiper v-if="sliders.length > 1" :options="swiperOptions">
        <SwiperSlide
          v-for="slider in sliders"
          :key="slider.id"
          :style="{
            'background-image': `url(/storage/${slider.image_desktop})`
          }"
          class="restaurant_slider_image"
        />
      </Swiper>
      <Swiper v-else :options="swiperOptions">
        <SwiperSlide
          :style="{
            'background-image': `url(/storage/${hero.image_desktop})`
          }"
          class="restaurant_slider_image"
        ></SwiperSlide>
      </Swiper>

      <Swiper v-if="sliders.length > 1" :options="swiperOptions">
        <SwiperSlide
          v-for="slider in sliders"
          :key="slider.id"
          :style="{
            'background-image': `url(/storage/${slider.image_mobile})`
          }"
          class="restaurant_slider_image restaurant_slider_image--mobile"
        />
      </Swiper>
      <Swiper v-else :options="swiperOptions">
        <SwiperSlide
          :style="{
            'background-image': `url(/storage/${hero.image_mobile})`
          }"
          class="restaurant_slider_image restaurant_slider_image--mobile"
        />
      </Swiper>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import ClickOutside from "vue-click-outside";

export default {
  directives: {
    ClickOutside
  },

  components: {
    Swiper,
    SwiperSlide
  },

  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        }
      }
    };
  },

  mounted() {
    this.popupItem = this.$refs.cart_popup;
  },

  computed: {
    ...mapState("auth", ["isAuthenticated"]),
    ...mapState("cart", ["cart"]),
    ...mapGetters("cart", ["itemsCount"]),
    ...mapState(["hero", "showCart", "sliders", "showGeneralSidebar"]),
    isHome() {
      return this.$route.name === "home";
    }
  },

  methods: {
    ...mapActions("auth", ["signOut"]),
    ...mapActions("cart", ["getCart", "removeItem", "changeItemQuantity"]),
    ...mapActions(["toggleGeneralSidebar", "toggleCart"]),
    async setLocale(locale) {
      this.$cookies.set("locale", locale, Infinity);
      if (this.isAuthenticated) {
        await this.$http.patch("/api/user/set-locale");
      }
      this.$router.go(0);
    },
    logout() {
      this.signOut().then(() => {
        if (this.$route.name !== "home") {
          this.$router.replace({ name: "home" });
        }
      });
    },
    gotoCart() {
      this.toggleCart(false);
      if (this.$route.name !== "cart") {
        this.$router.push({ name: "cart" });
      }
    }
  }
};
</script>
