var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.$route.name !== 'login' &&
      _vm.$route.name !== 'register' &&
      _vm.$route.name !== 'verify' &&
      _vm.$route.name !== 'contact_us' &&
      _vm.$route.name !== 'password-reset'
  )?_c('footer',[_c('div',{staticClass:"container"},[_vm._v(" Copyright "+_vm._s(new Date().getFullYear())+" © Riccardo Giraudi - "),_c('a',{staticStyle:{"color":"#4c4641","text-decoration":"underline"},attrs:{"href":"/CGV-et-CGU-DELOVERY.pdf","target":"_blank"}},[_vm._v(" Legals Infos ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }