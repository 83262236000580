<template>
  <div>
    <Header />

    <router-view></router-view>

    <Footer />
  </div>
</template>

<script>
import Header from "./partials/Header";
import Footer from "./partials/Footer";

export default {
  components: {
    Header,
    Footer
  }
};
</script>

<!--suppress CssUnknownTarget -->
<style lang="scss" scoped>
@import "~@/assets/css/bootstrap.min.css";
@import "~@/assets/css/clear.css";
@import "~@/assets/css/jquery.formstyler.css";
@import "~@/assets/css/jquery.formstyler.theme.css";
@import "~@/assets/css/login.css";
@import "~@/assets/css/main.css";
@import "~@/assets/css/my_account.css";
@import "~@/assets/css/register.css";
@import "~@/assets/css/shopcart.css";
@import "~@/assets/css/media.css";
</style>
